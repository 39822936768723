<script>
import { Inertia } from "@inertiajs/inertia";
import { Head, Link } from "@inertiajs/inertia-vue3";
import ProductBox from "@/Pages/Shared/ProductBox.vue";
import VerticalProductBox from "@/Pages/Shared/VerticalProductBox.vue";
import Loader from "@/Pages/Shared/Loader.vue";
import Layout from "@/Pages/Shared/Layout.vue";
import Comments from "@/Pages/Shared/Comments.vue";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import CategoryBox from "@/Pages/Shared/CategoryBox.vue";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";

export default {
    components: {
        ProductBox,
        VerticalProductBox,
        Link,
        Head,
        Splide,
        SplideSlide,
        SplideTrack,
        CategoryBox,
        Loader,
        Comments,
        TransitionRoot,
        TransitionChild,
        Dialog,
        DialogPanel,
        DialogTitle
    },
    props: {
        sliders: Object,
        allProducts: Object,
        discounted: Object,
        mostRentedProducts: Object,
        category_telefon: Object,
        category_bilgisayar: Object,
        category_tablet: Object,
        category_saat: Object,
        category_oyun: Object,
        category_kameralar: Object,
        category_ses: Object,
        category_evaletleri: Object,
        category_emobilite: Object,
        category_saglik: Object,
        category_spor: Object,
        category_eveglence: Object,
        auth: Object
    },
    layout: Layout,
    data() {
        return {
            selectedSpeedCategory: 1,
            loaderActive: false,
            selectedSpeedCategoryProducts: this.category_telefon,
            isOpen: false
        };
    },
    setup() {
        const options = {
            rewind: true,
            gap: "1rem",
            perPage: 1,
            arrows: false
        };

        return { options };
    },
    methods: {
        splidedArray(arr, size) {
            return arr.reduce((acc, e, i) => (i % size ? acc[acc.length - 1].push(e) : acc.push([e]), acc), []);
        },
        changeSpeedCategory(id) {
            // if not changed, return
            if (this.selectedSpeedCategory === id) return;

            this.selectedSpeedCategory = id;
            this.loaderActive = true;
            Inertia.reload({
                only: ["category_" + this.selectedSpeedCategory],
                onFinish: () => {
                    this.loaderActive = false;
                    this.selectedSpeedCategoryProducts = this["category_" + this.selectedSpeedCategory];
                },
                onSuccess: () => {
                    let categoryProducts = this["category_" + this.selectedSpeedCategory].items.element.products.data;
                    let categoryProductsDL = [];
                    categoryProducts.map((item, keys) => {
                        let product = {};
                        let subscribetionMonthsOrdered = [];
                        if (item.variants[0]?.prices.length > 0) subscribetionMonthsOrdered = Object.entries(item.variants[0]?.prices).sort(([, a], [, b]) => a.subscription_months.value - b.subscription_months.value);
                        let nonZeroPrices = item.variants[0]?.prices.filter((price) => price.price.value > 0);
                        subscribetionMonthsOrdered = subscribetionMonthsOrdered.filter((price) => price[1].price.value > 0);
                        let productPrice = subscribetionMonthsOrdered[+Object.keys(nonZeroPrices).length - 1]?.[1]?.price?.value / 100;

                        product.item_id = item.id;
                        product.item_name = item.attribute_data.name.tr;
                        product.price = productPrice;
                        product.item_brand = item.brand.name;
                        product.item_category = item.collections[0]?.attribute_data?.name?.tr;
                        product.item_category2 = item.collections[1]?.attribute_data?.name?.tr ?? "";
                        product.item_list_id = "HOME-KG";
                        product.item_list_name = "Homepage - Kategoriler " + this.selectedSpeedCategory + " List";
                        product.index = keys;
                        categoryProductsDL.push(product);
                    });
                    dataLayer.push({
                        event: "view_item_list",
                        ecommerce: {
                            item_list_id: "HOME-KG",
                            item_list_name: "Homepage - Kategoriler " + this.selectedSpeedCategory + " List",
                            items: categoryProductsDL
                        }
                    });
                }
            });
        },
        closeModal() {
            console.log(this.isOpen);
            this.isOpen = false;
        },
        openModal() {
            this.isOpen = true;
        }
    },
    created() {
        Inertia.reload({
            only: ["allProducts", "mostRentedProducts", "discounted", "category_telefon"],
            onSuccess: () => {
                let lastAddedProducts = this.allProducts.items.data;
                let lastAddedProductsDL = [];
                lastAddedProducts.map((item, keys) => {
                    let product = {};
                    let subscribetionMonthsOrdered = [];
                    if (item.variants[0]?.prices.length > 0) subscribetionMonthsOrdered = Object.entries(item.variants[0]?.prices).sort(([, a], [, b]) => a.subscription_months.value - b.subscription_months.value);
                    let nonZeroPrices = item.variants[0]?.prices.filter((price) => price.price.value > 0);
                    subscribetionMonthsOrdered = subscribetionMonthsOrdered.filter((price) => price[1].price.value > 0);
                    let productPrice = subscribetionMonthsOrdered[+Object.keys(nonZeroPrices).length - 1]?.[1]?.price?.value / 100;

                    product.item_id = item.id;
                    product.item_name = item.attribute_data.name.tr;
                    product.price = productPrice;
                    product.item_brand = item.brand.name;
                    product.item_category = item.collections[0]?.attribute_data?.name?.tr;
                    product.item_category2 = item.collections[1]?.attribute_data?.name?.tr ?? "";
                    product.item_list_id = "HOME-SEU";
                    product.item_list_name = "Homepage - Son Eklenen Ürünler List";
                    product.index = keys;
                    lastAddedProductsDL.push(product);
                });
                dataLayer.push({
                    event: "view_item_list",
                    ecommerce: {
                        item_list_id: "HOME-SEU",
                        item_list_name: "Homepage - Son Eklenen Ürünler List",
                        items: lastAddedProductsDL
                    }
                });

                let discountedProducts = this.discounted.items.data;
                let discountedProductsDL = [];
                discountedProducts.map((item, keys) => {
                    let product = {};
                    let subscribetionMonthsOrdered = [];
                    if (item.variants[0]?.prices.length > 0) subscribetionMonthsOrdered = Object.entries(item.variants[0]?.prices).sort(([, a], [, b]) => a.subscription_months.value - b.subscription_months.value);
                    let nonZeroPrices = item.variants[0]?.prices.filter((price) => price.price.value > 0);
                    subscribetionMonthsOrdered = subscribetionMonthsOrdered.filter((price) => price[1].price.value > 0);
                    let productPrice = subscribetionMonthsOrdered[+Object.keys(nonZeroPrices).length - 1]?.[1]?.price?.value / 100;

                    product.item_id = item.id;
                    product.item_name = item.attribute_data.name.tr;
                    product.price = productPrice;
                    product.item_brand = item.brand.name;
                    product.item_category = item.collections[0]?.attribute_data?.name?.tr;
                    product.item_category2 = item.collections[1]?.attribute_data?.name?.tr ?? "";
                    product.item_list_id = "HOME-IUL";
                    product.item_list_name = "Homepage - İndirimli Ürünler List";
                    product.index = keys;
                    discountedProductsDL.push(product);
                });
                dataLayer.push({
                    event: "view_item_list",
                    ecommerce: {
                        item_list_id: "HOME-IUL",
                        item_list_name: "Homepage - İndirimli Ürünler List",
                        items: discountedProductsDL
                    }
                });

                let mostRentedProducts = this.mostRentedProducts.items.data;
                let mostRentedProductsDL = [];
                mostRentedProducts.map((item, keys) => {
                    let product = {};
                    let subscribetionMonthsOrdered = [];
                    if (item.variants[0]?.prices.length > 0) subscribetionMonthsOrdered = Object.entries(item.variants[0]?.prices).sort(([, a], [, b]) => a.subscription_months.value - b.subscription_months.value);
                    let nonZeroPrices = item.variants[0]?.prices.filter((price) => price.price.value > 0);
                    subscribetionMonthsOrdered = subscribetionMonthsOrdered.filter((price) => price[1].price.value > 0);
                    let productPrice = subscribetionMonthsOrdered[+Object.keys(nonZeroPrices).length - 1]?.[1]?.price?.value / 100;

                    product.item_id = item.id;
                    product.item_name = item.attribute_data.name.tr;
                    product.price = productPrice;
                    product.item_brand = item.brand.name;
                    product.item_category = item.collections[0]?.attribute_data?.name?.tr;
                    product.item_category2 = item.collections[1]?.attribute_data?.name?.tr ?? "";
                    product.item_list_id = "HOME-ECKU";
                    product.item_list_name = "Homepage - En Çok Kiralanan Ürünler List";
                    product.index = keys;
                    mostRentedProductsDL.push(product);
                });
                dataLayer.push({
                    event: "view_item_list",
                    ecommerce: {
                        item_list_id: "HOME-ECKU",
                        item_list_name: "Homepage - En Çok Kiralanan Ürünler List",
                        items: mostRentedProductsDL
                    }
                });
            }
        });
    },
    computed: {
        splidedAllProducts() {
            return this.splidedArray(this.allProducts.items.data, 1);
        },
        splidedDiscounted() {
            // Take first 8 items than split them into 2
            return this.splidedArray(this.discounted.items.data.slice(0, 16), 1);
        }
    },
    watch: {
        // whenever question changes, this function will run
        category_telefon(newRes, oldRes) {
            this.selectedSpeedCategoryProducts = newRes;
        }
    },
    mounted() {
        // calculate isOpen with cookie content
        let cookies = document.cookie.split(";");
        let isOpenCookie = null;
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i].split("=");
            if (cookie[0].trim() === "isOpen") {
                isOpenCookie = true;
            }
        }

        if (isOpenCookie) {
            this.isOpen = false;
        } else {
            this.isOpen = true;
            let date = new Date();
            date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);
            const expires = "expires=" + date.toUTCString();
            document.cookie = "isOpen" + "=" + "true" + "; " + expires + "; path=/";
        }
    }
};
</script>

<template>
    <Head title="Gemfinite | Diamond">
        <meta name="description" content="Telefondan Akıllı Süpürgeye Birçok Teknoloji Ürününü Kolayca Kiralayabildiğin Kiralabunu! Satın Almadan, Kredi Kartını Bloke Etmeden Kiralama Özgürlüğü." />
    </Head>

    <main class="my-7 lg:my-12">
        <section class="-mt-7 lg:-mt-12">
            <!-- Slider -->
            <div class="w-full mx-auto">
                <div class="sliderclass">
                    <splide :options="{ rewind: true, gap: '1rem', perPage: 1, arrows: false }" class="block mts:hidden ts:hidden">
                        <splide-slide v-for="item in sliders.filter((item) => item.type === 'mobil')">
                            <Link :href="item.url">
                                <img class="w-full" :src="item.media[0].original_url" alt="" loading="lazy" />
                            </Link>
                        </splide-slide>
                    </splide>

                    <splide :options="options" class="hidden mts:block ts:hidden">
                        <splide-slide v-for="item in sliders.filter((item) => item.type === 'tablet')">
                            <Link :href="item.url">
                                <img class="w-full" :src="item.media[0].original_url" alt="" loading="lazy" />
                            </Link>
                        </splide-slide>
                    </splide>

                    <splide :options="options" class="hidden mts:hidden ts:block">
                        <splide-slide v-for="item in sliders.filter((item) => item.type === 'desktop')">
                            <Link :href="item.url">
                                <img class="w-full" :src="item.media[0].original_url" alt="" loading="lazy" />
                            </Link>
                        </splide-slide>
                    </splide>
                </div>
            </div>
        </section>

        <section class=" py-1 md:py-9 bg-[#f8f8f8]">
            <div class="flex mx-auto flex-col max-w-kbmobile md:max-w-tablet ts:max-w-ts 2xl:max-w-7xl">
                <div class="flex w-full justify-center">
                    <div class="text-2xl md:text-3xl my-2 lg:my-0 mx-0 lg:mx-4 self-center text-center w-full md:w-auto">En Sevilen Parçalar</div>
                </div>
                <div class="flex flex-col max-h-p-box space-x-1 pl-0 lg:pl-0 lg:p-0 lg:mt-9">
                    <Splide :has-track="false" aria-label="" class="w-full mt-5" :options="{ rewind: true, perPage: 4, pagination: false, pagination: false, breakpoints: { 640: { perPage: 1 }, 1100: { perPage: 4 } } }" v-if="mostRentedProducts">
                        <SplideTrack class="w-full">
                            <SplideSlide class="flex w-full" v-for="(product, index) in mostRentedProducts.items.data.slice(0, 4)">
                                <category-box :product="product" :new-container-classes="`w-full homepage`" />
                            </SplideSlide>
                        </SplideTrack>
                        <div class="splide__arrows">
                            <button class="splide__arrow splide__arrow--prev !-left-9">
                                <svg class="" xmlns="http://www.w3.org/2000/svg" width="11.926" height="20.737" viewBox="0 0 11.926 20.737">
                                    <path id="Path_18" data-name="Path 18" d="M86.422,320.457a1.558,1.558,0,0,1,2.2,0l7.71,7.71,7.71-7.71a1.557,1.557,0,1,1,2.2,2.2l-8.811,8.811a1.558,1.558,0,0,1-2.2,0l-8.811-8.811A1.558,1.558,0,0,1,86.422,320.457Z" transform="translate(331.927 -85.966) rotate(90)" fill="#231f20" />
                                </svg>
                            </button>
                            <button class="splide__arrow splide__arrow--next !-right-9">
                                <svg class="rotate-180" xmlns="http://www.w3.org/2000/svg" width="11.926" height="20.737" viewBox="0 0 11.926 20.737">
                                    <path id="Path_18" data-name="Path 18" d="M86.422,320.457a1.558,1.558,0,0,1,2.2,0l7.71,7.71,7.71-7.71a1.557,1.557,0,1,1,2.2,2.2l-8.811,8.811a1.558,1.558,0,0,1-2.2,0l-8.811-8.811A1.558,1.558,0,0,1,86.422,320.457Z" transform="translate(331.927 -85.966) rotate(90)" fill="#231f20" />
                                </svg>
                            </button>
                        </div>
                    </Splide>
                    <div v-else>
                        <loader :active="true" message="Please wait 5 seconds" />
                    </div>
                </div>
            </div>
        </section>

        <section class="flex flex-wrap mx-auto max-w-kbmobile mts:max-w-tablet ts:max-w-ts 2xl:max-w-7xl w-full my-12">
            <div class="font-bold text-2xl ts:text-3xl self-center text-center w-full">Nasıl Çalışır</div>
            <div class="flex flex-wrap mt-4 w-full">
                <div class="w-full lg:w-1/3 mts:pl-4">
                    <div class="w-full h-full flex flex-col justify-start items-center hover:shadow-searchshadow p-0 pb-3 min-h-[200px]">
                        <div class="flex w-full items center justify-center mb-1"><img class=" w-full" src="../../images/howitworks/nasil-calisir-1.jpg"></div>
                        <div class="text-base mts:text-lg w-full mt-2 text-center text-kbgreen">Pırlantanı Seç</div>
                        <div class="text-sm mts:text-base font-santralregular w-full mt-2 text-center">Favori ürününüzü kiralamak istediğiniz tarih aralığını seçin, ürünü sepete ekleyin.</div>
                    </div>
                </div>
                <div class="w-full lg:w-1/3 mts:pl-4 ">
                    <div class="w-full h-full flex flex-col justify-start items-center hover:shadow-searchshadow p-0 pb-3 min-h-[200px]">
                        <div class="flex w-full items center justify-center mb-1"><img class=" w-full" src="../../images/howitworks/nasil-calisir-2.jpg"></div>
                        <div class="text-base mts:text-lg w-full mt-2 text-center text-kbgreen">Pırlantanı Kullan</div>
                        <div class="text-sm mts:text-base font-santralregular w-full mt-2 text-center">Seçtiğiniz ürün istediğiniz tarihte kapınıza gelsin. Ürününüzün tadını çıkarın.
                        </div>
                    </div>
                </div>
                <div class="w-full lg:w-1/3 mts:pl-4">
                    <div class="w-full h-full flex flex-col justify-start items-center hover:shadow-searchshadow p-0 pb-3 min-h-[200px]">
                        <div class="flex w-full items center justify-center mb-1"><img class=" w-full" src="../../images/howitworks/nasil-calisir-3.jpg"></div>
                        <div class="text-base mts:text-lg w-full mt-2 text-center text-kbgreen">Geri Gönderin</div>
                        <div class="text-sm mts:text-base font-santralregular w-full mt-2 text-center">Belirlediğiniz tarih aralığı dolduğunda ürününüzü bize geri gönderin. Kiralama sonrası bakım işlemlerini düşünmeyin.
                        </div>
                    </div>
                </div>

            </div>
        </section>
        <section class=" mt-3 bg-[#f8f8f8] py-5">
            <div class="flex mx-auto flex-col max-w-tablet ts:max-w-ts 2xl:max-w-7xl">
                <div class="flex justify-center px-4 md:px-0">
                    <div class="text-3xl self-center text-center w-full lg:w-auto py-6">En Beğenilen Yüzüklere Göz At</div>
                </div>
                <div class="flex flex-wrap">
                    <div v-if="false" class="w-full mt-4 overflow-x-scroll">
                        <ul class="flex text-sm bg-white">
                            <li class="flex justify-between items-center group" @click="changeSpeedCategory('yuzuk')">
                                <a class="cursor-pointer w-full py-3 px-3 active:bg-kbgreen focus:bg-kbgreen group-hover:bg-kbgreen group-hover:text-white focus:text-white active:text-white">
                                    <div class="flex items-center">
                                        <span class="whitespace-nowrap text-base">Yüzük</span>
                                    </div>
                                </a>
                            </li>
                            <li class="flex justify-between items-center group" @click="changeSpeedCategory('kolye')">
                                <a class="cursor-pointer w-full py-3 px-3 active:bg-kbgreen focus:bg-kbgreen group-hover:bg-kbgreen group-hover:text-white focus:text-white active:text-white">
                                    <div class="flex items-center">
                                        <span class="whitespace-nowrap text-base">Kolye</span>
                                    </div>
                                </a>
                            </li>
                            <li class="flex justify-between items-center group" @click="changeSpeedCategory('kupe')">
                                <a class="cursor-pointer w-full py-3 px-3 active:bg-kbgreen focus:bg-kbgreen group-hover:bg-kbgreen group-hover:text-white focus:text-white active:text-white">
                                    <div class="flex items-center">
                                        <span class="whitespace-nowrap text-base">Küpe</span>
                                    </div>
                                </a>
                            </li>
                            <li class="flex justify-between items-center group" @click="changeSpeedCategory('bileklik')">
                                <a class="cursor-pointer w-full py-3 px-3 active:bg-kbgreen focus:bg-kbgreen group-hover:bg-kbgreen group-hover:text-white focus:text-white active:text-white active">
                                    <div class="flex items-center">
                                        <span class="whitespace-nowrap text-base">Bileklik</span>
                                    </div>
                                </a>
                            </li>
                            <li class="flex justify-between items-center group" @click="changeSpeedCategory('pirlanta-tektas-yuzuk')">
                                <a class="cursor-pointer w-full py-3 px-3 active:bg-kbgreen focus:bg-kbgreen group-hover:bg-kbgreen group-hover:text-white focus:text-white active:text-white">
                                    <div class="flex items-center">
                                        <span class="whitespace-nowrap text-base">Pırlanta Tektaş Yüzük</span>
                                    </div>
                                </a>
                            </li>
                            <li class="flex justify-between items-center group" @click="changeSpeedCategory('pirlanta-bestas-yuzuk')">
                                <a class="cursor-pointer w-full py-3 px-3 active:bg-kbgreen focus:bg-kbgreen group-hover:bg-kbgreen group-hover:text-white focus:text-white active:text-white">
                                    <div class="flex items-center">
                                        <span class="whitespace-nowrap text-base">Pırlanta Bektaş Yüzük</span>
                                    </div>
                                </a>
                            </li>
                            <li class="flex justify-between items-center group" @click="changeSpeedCategory('pirlanta-tamtur-yuzuk')">
                                <a class="cursor-pointer w-full py-3 px-3 active:bg-kbgreen focus:bg-kbgreen group-hover:bg-kbgreen group-hover:text-white focus:text-white active:text-white active">
                                    <div class="flex items-center">
                                        <span class="whitespace-nowrap text-base">Pırlanta Tamtur Yüzük</span>
                                    </div>
                                </a>
                            </li>
                            <li class="flex justify-between items-center group" @click="changeSpeedCategory('pirlanta-kolye')">
                                <a class="cursor-pointer w-full py-3 px-3 active:bg-kbgreen focus:bg-kbgreen group-hover:bg-kbgreen group-hover:text-white focus:text-white active:text-white active">
                                    <div class="flex items-center">
                                        <span class="whitespace-nowrap text-base">Pırlanta Kolye</span>
                                    </div>
                                </a>
                            </li>
                            <li class="flex justify-between items-center group" @click="changeSpeedCategory('pirlanta-tektas-kolye')">
                                <a class="cursor-pointer w-full py-3 px-3 active:bg-kbgreen focus:bg-kbgreen group-hover:bg-kbgreen group-hover:text-white focus:text-white active:text-white">
                                    <div class="flex items-center">
                                        <span class="whitespace-nowrap text-base">Pırlanta Tektaş Kolye</span>
                                    </div>
                                </a>
                            </li>
                            <li class="flex justify-between items-center group" @click="changeSpeedCategory('pirlanta-kupe')">
                                <a class="cursor-pointer w-full py-3 px-3 active:bg-kbgreen focus:bg-kbgreen group-hover:bg-kbgreen group-hover:text-white focus:text-white active:text-white active">
                                    <div class="flex items-center">
                                        <span class="whitespace-nowrap text-base">Pırlanta Küpe</span>
                                    </div>
                                </a>
                            </li>
                            <li class="flex justify-between items-center group" @click="changeSpeedCategory('pirlanta-tektas-kupe')">
                                <a class="cursor-pointer w-full py-3 px-3 active:bg-kbgreen focus:bg-kbgreen group-hover:bg-kbgreen group-hover:text-white focus:text-white active:text-white active">
                                    <div class="flex items-center">
                                        <span class="whitespace-nowrap text-base">Pırlanta Tektaş Küpe</span>
                                    </div>
                                </a>
                            </li>
                            <li class="flex justify-between items-center group" @click="changeSpeedCategory('pirlanta-halka-kupe')">
                                <a class="cursor-pointer w-full py-3 px-3 active:bg-kbgreen focus:bg-kbgreen group-hover:bg-kbgreen group-hover:text-white focus:text-white active:text-white active">
                                    <div class="flex items-center">
                                        <span class="whitespace-nowrap text-base">Pırlanta Halka Küpe</span>
                                    </div>
                                </a>
                            </li>
                            <li class="flex justify-between items-center group" @click="changeSpeedCategory('pirlanta-suyolu-bileklik')">
                                <a class="cursor-pointer w-full py-3 px-3 active:bg-kbgreen focus:bg-kbgreen group-hover:bg-kbgreen group-hover:text-white focus:text-white active:text-white active">
                                    <div class="flex items-center">
                                        <span class="whitespace-nowrap text-base">Pırlanta Suyolu Bileklik</span>
                                    </div>
                                </a>
                            </li>
                            <li class="flex justify-between items-center group" @click="changeSpeedCategory('pirlanta-baget-bileklik')">
                                <a class="cursor-pointer w-full py-3 px-3 active:bg-kbgreen focus:bg-kbgreen group-hover:bg-kbgreen group-hover:text-white focus:text-white active:text-white active">
                                    <div class="flex items-center">
                                        <span class="whitespace-nowrap text-base">Pırlanta Baget Bileklik</span>
                                    </div>
                                </a>
                            </li>

                        </ul>
                    </div>
                    <div class="w-full mt-4">
                        <div class=" p-3 lg:p-0" :class="[loaderActive ? 'grid h-[462px] place-items-center' : '']">
                            <loader :active="loaderActive" message="Please wait 5 seconds" />
                            <Splide :has-track="false" aria-label="" :options="{ rewind: true, drag: true, arrows: false, perPage: 4, pagination: false, breakpoints: { 640: { perPage: 1 }, 1100: { perPage: 2 }, 1270: { perPage: 4 } } }">
                                <SplideTrack>
                                    <SplideSlide v-for="(product, index) in selectedSpeedCategoryProducts?.items?.element?.products?.data" class="flex">
                                        <category-box :product="product" :new-container-classes="`w-[100%] homepage`" :auth="auth" v-if="!loaderActive" />
                                    </SplideSlide>
                                </SplideTrack>
                            </Splide>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </main>
    <TransitionRoot appear :show="false" as="template">
        <Dialog as="div" @close="closeModal" class="relative z-10">
            <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-black bg-opacity-25" />
            </TransitionChild>
            <div class="fixed inset-0 overflow-y-auto">
                <div class="flex min-h-full items-center justify-center p-4 text-center">
                    <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
                        <DialogPanel class="max-w-[350px] lg:max-w-[550px] transform overflow-hidden rounded-sm bg-white px-1 py-1 z-70 text-left align-middle shadow-xl transition-all flex flex-col justify-center items-center">
                            <img class="w-full hidden lg:block" src="../../images/popup/popup-large.png" alt="" />
                            <img class="w-full block lg:hidden" src="../../images/popup/popup-large.png" alt="" />
                            <div class="font-santralextrabold absolute top-0 right-0 cursor-pointer px-1 text-base bg-white rounded-lg" @click="closeModal">X</div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
<style>
.homepage img {
    max-height: 230px !important;
}

.homepage picture {
    min-height: 230px !important;
}

.homepage video {
    max-height: 230px !important;
}

.homepage .video-grid {
    min-height: 230px !important;
}
</style>
